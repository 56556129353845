import {
  DraggableComponent,
  DroppableComponent,
  onDragEnd
} from '@components/common/DragAndDrop'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import useTableStyles from '@styles/table/table'
import {
  sortAlphaNum,
  sortDate,
  sortNumber,
  sortString
} from '@utils/sortTables'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import CommonPagination from '../Pagination'
import PlaceHolder from '../PlaceHolder'

const CollapsableRow = props => {
  const classesTable = useTableStyles()

  const {
    datumItems,
    columns,
    open,
    dragAndDropEnabled,
    collapseEnabled,
    patchData,
    mutateData,
    lastRow = false,
    onChildRowClick,
    sortProps = {},
    tableStyle = {},
    selectionProps = {}
  } = props

  const { order } = sortProps

  const {
    selectionEnabled,
    itemsSelected,
    setItemsSelected,
    onSelectionChange
  } = selectionProps

  const [items, setItems] = useState([])
  // const [disableDrag, setDisableDrag] = useState(false)

  useEffect(() => {
    setItems(
      datumItems ? [...datumItems]?.sort((a, b) => a.order - b.order) : []
    )
  }, [datumItems, order])

  // item props : {
  //   id: number | string,
  //   className: string,
  //   clickable: boolean,
  //   [col.field]:
  //     (string / component) | { colSpan: number, label: string / component, style: css object },
  // }

  return collapseEnabled ? (
    <TableRow hover={false}>
      <TableCell
        colSpan="500%"
        sx={{
          p: 0,
          borderBottom: t =>
            lastRow ? 0 : `1px solid ${t.palette.other.stroke}`
        }}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table aria-label="zones_table" sx={tableStyle}>
            <TableBody
              component={
                dragAndDropEnabled
                  ? DroppableComponent({
                      onDragEnd: result =>
                        onDragEnd({
                          result,
                          items,
                          setItems,
                          patchData,
                          mutateData
                          // setDisableDrag
                        })
                    })
                  : 'tbody'
              }
            >
              {items.map((item, index) => (
                <TableRow
                  key={`${item.id}-${index}`}
                  className={classesTable[item.className ?? 'row']}
                  component={
                    dragAndDropEnabled
                      ? DraggableComponent({
                          draggableId: item.id.toString(),
                          index,
                          className: classesTable[item.className ?? 'row']
                          // dragHandleDisabled: disableDrag
                        })
                      : 'tr'
                  }
                >
                  {columns.map(col => {
                    return col && !col.hidden ? (
                      <TableCell
                        key={`${col.field}-${item.id}`}
                        sx={{
                          width: col.width ?? 'fit-content',
                          ...col.cellStyle,
                          ...item[col.field]?.style
                        }}
                        align={col.align ?? 'left'}
                        colSpan={col.colSpan || item[col.field]?.colSpan || 1}
                      >
                        {item[col.field]?.label ?? item[col.field]}
                      </TableCell>
                    ) : undefined
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  ) : (
    items.map((item, index) => (
      <TableRow
        key={`${item.id}-${index}`}
        className={classesTable[item.className ?? 'row']}
        component={
          dragAndDropEnabled
            ? DraggableComponent({
                draggableId: item.id.toString(),
                index,
                className: classesTable[item.className ?? 'row']
                // dragHandleDisabled: disableDrag
              })
            : 'tr'
        }
        onClick={onChildRowClick ? e => onChildRowClick(e, item) : undefined}
        hover={item.hover ?? true}
        sx={{
          position: 'relative',
          ...(onChildRowClick &&
            (item.clickable ?? true) && { cursor: 'pointer' })
        }}
      >
        {selectionEnabled && (
          <TableCell>
            <Checkbox
              size="small"
              checked={itemsSelected?.includes(item.id)}
              onChange={() => {
                const selection = itemsSelected?.includes(item.id)
                  ? itemsSelected.filter(x => x != item.id)
                  : [...itemsSelected, item.id]
                setItemsSelected(selection)

                onSelectionChange(selection)
              }}
            />
          </TableCell>
        )}
        {columns.map(col =>
          col && !col.hidden && item[col.field] !== false ? (
            <TableCell
              key={`${col.field}-${item.id}`}
              sx={{
                width: col.width ?? 'fit-content',
                ...col.cellStyle,
                ...item[col.field]?.style
              }}
              align={col.align ?? 'left'}
              colSpan={col.colSpan || item[col.field]?.colSpan || 1}
            >
              {item[col.field]?.label ?? item[col.field]}
            </TableCell>
          ) : undefined
        )}
      </TableRow>
    ))
  )
}

const RowTemplate = props => {
  const classesTable = useTableStyles()

  const {
    datum,
    index,
    columns,
    dragAndDropEnabled,
    collapseProps = {},
    disableDrag,
    isNonDraggableRow = false,
    lastRow = false,
    open,
    setOpen,
    selectionProps = {},
    onRowClick,
    onChildRowClick,
    sortProps,
    tableStyle
  } = props

  const {
    collapseEnabled = false,
    dragAndDropEnabled: collapseDragAndDropEnabled = false,
    patchData: collapsePatchData = () => null,
    mutateData: collapseMutateData = () => null
  } = collapseProps

  const {
    selectionEnabled,
    itemsSelected,
    setItemsSelected,
    onSelectionChange
  } = selectionProps

  const wholeRowClickable = !columns?.filter(col => col.clickable === true)
    ?.length
  const rowClickable = onRowClick && (datum?.clickable ?? true)

  const itemsIdsList = datum?.items?.map(item => item.id)
  const allChildItemsSelected = itemsIdsList?.every(id =>
    itemsSelected?.includes(id)
  )
  const someChildItemsSelected = itemsIdsList?.some(id =>
    itemsSelected?.includes(id)
  )

  return (
    <Fragment>
      {!datum.hidden && (
        <TableRow
          className={classesTable[datum.className ?? 'row']}
          component={
            dragAndDropEnabled
              ? DraggableComponent({
                  draggableId: datum.id.toString(),
                  index,
                  className: classesTable[datum.className ?? 'row'],
                  dragHandleDisabled: disableDrag,
                  dragHandleVisible: datum.draggable ?? true,
                  style: { ...(rowClickable && { cursor: 'pointer' }) },
                  onRowClick:
                    onRowClick && !selectionEnabled && wholeRowClickable
                      ? e => onRowClick(e, datum)
                      : undefined
                })
              : 'tr'
          }
          hover={datum.hover ?? false}
          // if selection is enabled the rowClick is on table cells to avoid event to happen if click on checkbox
          onClick={
            onRowClick && !selectionEnabled && wholeRowClickable
              ? e => onRowClick(e, datum)
              : undefined
          }
          sx={{ ...(rowClickable && { cursor: 'pointer' }) }}
        >
          {isNonDraggableRow && <TableCell />}
          {selectionEnabled && (
            <TableCell>
              <Checkbox
                size="small"
                checked={
                  datum.items
                    ? allChildItemsSelected
                    : itemsSelected?.includes(datum.id)
                }
                {...(datum.items?.length > 0 && {
                  indeterminate:
                    someChildItemsSelected && !allChildItemsSelected
                })}
                onChange={() => {
                  let selection = []
                  if (!datum.items) {
                    selection = itemsSelected?.includes(datum.id)
                      ? itemsSelected.filter(x => x != datum.id)
                      : [...itemsSelected, datum.id]
                    setItemsSelected(selection)
                  } else {
                    selection = allChildItemsSelected
                      ? itemsSelected.filter(x => !itemsIdsList?.includes(x))
                      : [...itemsSelected, ...itemsIdsList]
                    setItemsSelected(selection)
                  }

                  onSelectionChange(selection)
                }}
              />
            </TableCell>
          )}
          {columns.map((col, colIndex) =>
            col && !col.hidden && datum[col.field] !== false ? (
              <TableCell
                key={`${col.field}-${datum.id}`}
                id={`tablecell-${col.field}-${datum.id}`}
                sx={{
                  width: col.width ?? 'fit-content',
                  ...col.cellStyle,
                  ...datum[col.field]?.style,
                  ...(col.clickable &&
                    !rowClickable && {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      '&:hover': { color: 'primary.main', fontWeight: 600 }
                    })
                }}
                align={col.align ?? 'left'}
                colSpan={col.colSpan || datum[col.field]?.colSpan || 1}
                onClick={
                  onRowClick &&
                  ((wholeRowClickable && selectionEnabled) ||
                    (!wholeRowClickable &&
                      col.clickable === true &&
                      rowClickable))
                    ? e => onRowClick(e, datum)
                    : onRowClick &&
                      ((wholeRowClickable && selectionEnabled) ||
                        (!wholeRowClickable &&
                          col.clickable === true &&
                          !rowClickable))
                    ? e => onRowClick(e, datum, col)
                    : undefined
                }
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  width="100%"
                  justifyContent={
                    col.align === 'right'
                      ? 'flex-end'
                      : col.align === 'center'
                      ? 'center'
                      : 'flex-start'
                  }
                >
                  {collapseEnabled && colIndex === 0 && !datum?.isSubtotal && (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        setOpen(prev =>
                          prev?.includes(datum.id)
                            ? prev.filter(p => p !== datum.id)
                            : [...prev, datum.id]
                        )
                      }}
                      data-testid="IconButton-fc5c4dfa-5840-42d0-a161-42eaff38181b"
                    >
                      {open ? (
                        <KeyboardArrowUp color="secondary" fontSize="small" />
                      ) : (
                        <KeyboardArrowDown color="secondary" fontSize="small" />
                      )}
                    </IconButton>
                  )}
                  <div style={{ width: '100%' }}>
                    {datum[col.field]?.label ?? datum[col.field]}
                  </div>
                </Stack>
              </TableCell>
            ) : undefined
          )}
        </TableRow>
      )}
      {datum.items && datum.items.length > 0 && (
        <CollapsableRow
          datum={datum}
          datumItems={datum.items}
          columns={columns}
          open={open}
          collapseEnabled={collapseEnabled}
          dragAndDropEnabled={collapseDragAndDropEnabled}
          patchData={collapsePatchData}
          mutateData={collapseMutateData}
          lastRow={lastRow}
          onChildRowClick={onChildRowClick}
          sortProps={sortProps}
          tableStyle={tableStyle}
          selectionProps={selectionProps}
        />
      )}
    </Fragment>
  )
}

const TableTemplate = React.forwardRef((props, ref) => {
  const classesTable = useTableStyles()

  const {
    headerProps = {},
    bodyProps = {},
    footerProps = {},
    dragAndDropProps = {},
    collapseProps = {},
    placeholderProps = {},
    sortProps = {},
    selectionProps = {},
    paginationProps = {},
    tableContainerStyle = {},
    tableStyle = {}
  } = props

  const {
    columns = [],
    extraTableHead = null,
    extraTableHeadRow = null,
    enabled: headerEnabled = true
  } = headerProps
  const {
    data = null,
    isLoading = false,
    onRowClick = null,
    onChildRowClick = null
  } = bodyProps
  const { data: footerData = [] } = footerProps
  const {
    patchData = () => null,
    mutateData = () => null,
    enabled: dragAndDropEnabled = false,
    NonDraggableRows
  } = dragAndDropProps

  const {
    src,
    width,
    height,
    text: placeholderText,
    enabled: placeholderEnabled = true,
    customPlaceholder = null,
    displayPlaceholder = false
  } = placeholderProps

  const { defaultOpen } = collapseProps

  const { enabled: sortingEnabled = false } = sortProps

  const {
    enabled: selectionEnabled = false,
    onSelectionChange = () => null,
    selectedItems = []
  } = selectionProps

  const { enabled: paginationEnabled = false } = paginationProps

  const [items, setItems] = useState(null)
  const [backupItems, setBackupItems] = useState(null)
  const [disableDrag, setDisableDrag] = useState(false)
  const [open, setOpen] = useState([])
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState({ direction: 'asc', counter: 0 })

  const [itemsSelected, setItemsSelected] = useState(selectedItems)

  const sortingFunction = useCallback(
    (data, sortBy, direction, isAlphaNum = false, objectField = false) => {
      const itemsCopy = [...data]
      itemsCopy.sort((a, b) => {
        let [el1, el2] = direction === 'asc' ? [a, b] : [b, a]
        if (objectField) [el1, el2] = direction === 'asc' ? [a, b] : [b, a]
        if (isAlphaNum) return sortAlphaNum(el1, el2, sortBy)
        else if (typeof el1[sortBy] == 'number')
          return sortNumber(el1, el2, sortBy)
        else if (typeof el1[sortBy] == 'string')
          return sortString(el1, el2, sortBy)
        else return sortDate(el1, el2, sortBy)
      })
      setItems(itemsCopy)
    },
    []
  )

  useEffect(() => {
    if (!isLoading && data) {
      if (orderBy && order && !order.isCustom)
        sortingFunction(data, orderBy, order.direction)
      else setItems(data)
      setBackupItems(data)
      !selectedItems?.length && setItemsSelected([])
    } else if (!isLoading && !data) {
      setItems([])
      setBackupItems([])
    }
  }, [
    data,
    isLoading,
    setItems,
    setBackupItems,
    selectedItems?.length,
    order,
    orderBy,
    sortingFunction
  ])

  useEffect(() => {
    if (items && defaultOpen === true) {
      const rowsIds = items.map(item => item.id)
      const nonDraggableRowsIds = NonDraggableRows?.map(item => item.id) || []
      setOpen([...rowsIds, ...nonDraggableRowsIds])
    } else if (Array.isArray(defaultOpen))
      setOpen(prev => [...prev, ...defaultOpen])
  }, [defaultOpen, items, setOpen, NonDraggableRows])

  useEffect(() => {
    if (defaultOpen === false) setOpen([])
    else if (Array.isArray(defaultOpen))
      setOpen(prev => [...prev, ...defaultOpen])
  }, [defaultOpen])

  const sortingChildrenFunction = (
    data,
    sortBy,
    direction,
    isAlphaNum = false
  ) => {
    const itemsCopy = [...data]
    itemsCopy.forEach(item => {
      item.items?.sort((a, b) => {
        const [el1, el2] = direction === 'asc' ? [a, b] : [b, a]
        if (isAlphaNum) return sortAlphaNum(el1, el2, sortBy)
        else if (typeof el1[sortBy] == 'number')
          return sortNumber(el1, el2, sortBy)
        else if (typeof el1[sortBy] == 'string')
          return sortString(el1, el2, sortBy)
        else return sortDate(el1, el2, sortBy)
      })
    })
    setItems(itemsCopy)
  }

  const sortItems = useCallback(
    col => {
      if (items) {
        const sortBy = col.sortBy || col.field
        const direction = order.direction === 'asc' ? 'desc' : 'asc'
        const counter = order.counter

        const itemsHaveChildren =
          items.filter(item => item.items?.length > 0)?.length > 0

        if (orderBy && sortBy != orderBy) {
          setOrder({ direction: 'asc', counter: 0 })
          setOrderBy('')
          if (!col.customSort) setItems(backupItems)
          return
        }

        if (col.customSort) {
          if (counter >= 2) {
            col.customSort('')
            setOrder({ direction: 'asc', counter: 0 })
            setOrderBy('')
            return
          }
          col.customSort(direction)
        } else {
          if (counter >= 2) {
            setItems(backupItems)
            setOrder({ direction: 'asc', counter: 0 })
            setOrderBy('')
            return
          }
          if (itemsHaveChildren)
            sortingChildrenFunction(items, sortBy, direction, col.isAlphaNum)
          else sortingFunction(items, sortBy, direction, col.isAlphaNum)
        }
        setOrder(prev => ({
          direction,
          counter: prev.counter + 1,
          isCustom: !!col?.customSort
        }))
        setOrderBy(sortBy)
      }
    },
    [
      order,
      setOrder,
      setOrderBy,
      items,
      setItems,
      backupItems,
      sortingFunction,
      orderBy
    ]
  )

  const itemsHaveChildren = items?.some(item => item.items)
  const itemsLength = itemsHaveChildren
    ? items?.flatMap(item => item.items).length
    : items?.length
  const allItemsSelected = itemsSelected?.length === itemsLength

  const dragHandleDisabled = Boolean(orderBy)

  return (
    <TableContainer
      className={classesTable.container}
      sx={tableContainerStyle}
      ref={ref}
    >
      <Table aria-label="table-template" sx={tableStyle}>
        {extraTableHead && (
          <TableHead>
            <TableRow>
              <TableCell colSpan="100%">{extraTableHead}</TableCell>
            </TableRow>
          </TableHead>
        )}
        {headerEnabled && (
          <TableHead>
            {extraTableHeadRow && (
              <TableRow>
                {dragAndDropEnabled && <TableCell />}
                {selectionEnabled && <TableCell />}
                {extraTableHeadRow.map(col =>
                  col && !col.hidden ? (
                    <TableCell
                      key={col.field}
                      sx={{
                        width: col.width ?? 'fit-content',
                        ...col.headerStyle
                      }}
                      align={col.align ?? 'left'}
                      colSpan={col.colSpan || 1}
                      className={classesTable.headerCell}
                    >
                      {col.title}
                    </TableCell>
                  ) : undefined
                )}
              </TableRow>
            )}
            <TableRow hover={false}>
              {dragAndDropEnabled && <TableCell />}
              {selectionEnabled && (
                <TableCell>
                  <Checkbox
                    size="small"
                    checked={allItemsSelected}
                    indeterminate={
                      itemsSelected?.length > 0 &&
                      itemsSelected?.length < itemsLength
                    }
                    onChange={() => {
                      const selection = allItemsSelected
                        ? []
                        : itemsHaveChildren
                        ? items?.flatMap(item => item.items.map(i => i.id))
                        : items?.map(i => i.id)
                      setItemsSelected(selection)
                      onSelectionChange(selection)
                    }}
                  />
                </TableCell>
              )}
              {columns.map(col =>
                col && !col.hidden ? (
                  <TableCell
                    key={col.field}
                    sx={{
                      width: col.width ?? 'fit-content',
                      ...col.headerStyle
                    }}
                    align={col.align ?? 'left'}
                    colSpan={col.colSpan || 1}
                    className={classesTable.headerCell}
                  >
                    {sortingEnabled &&
                    (col.sorting === true || col.sorting === undefined) ? (
                      <TableSortLabel
                        active={orderBy === (col.sortBy || col.field)}
                        direction={
                          orderBy === (col.sortBy || col.field)
                            ? order.direction
                            : 'asc'
                        }
                        onClick={() => sortItems(col)}
                      >
                        {col.title}
                      </TableSortLabel>
                    ) : (
                      col.title
                    )}
                  </TableCell>
                ) : undefined
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody
          component={
            dragAndDropEnabled
              ? DroppableComponent({
                  onDragEnd: result =>
                    onDragEnd({
                      result,
                      items,
                      setItems,
                      patchData,
                      mutateData,
                      setDisableDrag
                    })
                })
              : 'tbody'
          }
        >
          {(isLoading || !items) && (
            <TableRow hover={false} className={classesTable.row}>
              <TableCell align="center" colSpan="100%" sx={{ height: 300 }}>
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            items &&
            (!items?.length || displayPlaceholder) &&
            placeholderEnabled && (
              <TableRow hover={false} className={classesTable.row}>
                <TableCell align="center" colSpan="100%">
                  {!customPlaceholder ? (
                    <PlaceHolder
                      src={src}
                      width={width}
                      height={height}
                      wordingBordered={placeholderText}
                    />
                  ) : (
                    customPlaceholder
                  )}
                </TableCell>
              </TableRow>
            )}
          {!isLoading &&
            items &&
            items.length > 0 &&
            items.map((datum, index) => {
              return (
                <RowTemplate
                  key={`${datum.id}-${index}`}
                  datum={datum}
                  index={index}
                  columns={columns}
                  dragAndDropEnabled={dragAndDropEnabled}
                  collapseProps={collapseProps}
                  disableDrag={disableDrag || dragHandleDisabled}
                  lastRow={
                    index === data?.length - 1 && !NonDraggableRows?.length
                  }
                  onRowClick={onRowClick}
                  onChildRowClick={onChildRowClick}
                  open={open?.includes(datum.id)}
                  setOpen={setOpen}
                  selectionProps={{
                    selectionEnabled,
                    itemsSelected,
                    setItemsSelected,
                    onSelectionChange
                  }}
                  sortProps={{ sortingEnabled, order }}
                  tableStyle={tableStyle}
                />
              )
            })}
        </TableBody>
        {!isLoading && NonDraggableRows?.length > 0 && (
          <TableBody>
            {NonDraggableRows?.map((datum, index) => (
              <RowTemplate
                key={`${datum.id}-${index}`}
                datum={datum}
                index={index}
                columns={columns}
                dragAndDropEnabled={false}
                collapseProps={collapseProps}
                disableDrag={disableDrag}
                isNonDraggableRow
                lastRow={true}
                open={open?.includes(datum.id)}
                setOpen={setOpen}
                selectionProps={{
                  selectionEnabled,
                  itemsSelected,
                  setItemsSelected,
                  onSelectionChange
                }}
              />
            ))}
          </TableBody>
        )}
        {!isLoading &&
          items &&
          items.length > 0 &&
          (footerData.length > 0 || paginationEnabled) && (
            <TableFooter className={classesTable.footer}>
              {footerData.map(footer =>
                footer ? (
                  <TableRow
                    key={footer.id}
                    className={classesTable[footer.className ?? 'rowTotal']}
                    hover={false}
                  >
                    {dragAndDropEnabled && <TableCell />}
                    {selectionEnabled && <TableCell />}
                    {columns.map((col, colIndex) =>
                      col && !col.hidden ? (
                        <TableCell
                          key={`total-${col.field}-${colIndex}`}
                          sx={{
                            width: col.width ?? 'fit-content',
                            ...col.cellStyle,
                            ...footer[col.field]?.style,
                            fontWeight: 'bold'
                          }}
                          align={col.align ?? 'left'}
                          colSpan={
                            col.colSpan || footer[col.field]?.colSpan || 1
                          }
                        >
                          {footer[col.field]?.label ?? footer[col.field]}
                        </TableCell>
                      ) : undefined
                    )}
                  </TableRow>
                ) : undefined
              )}
              {paginationEnabled && <CommonPagination {...paginationProps} />}
            </TableFooter>
          )}
      </Table>
    </TableContainer>
  )
})

TableTemplate.propTypes = {
  headerProps: PropTypes.shape({
    columns: PropTypes.array,
    extraTableHead: PropTypes.any,
    extraTableHeadRow: PropTypes.array,
    enabled: PropTypes.bool
  }),
  bodyProps: PropTypes.shape({
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    onRowClick: PropTypes.func,
    onChildRowClick: PropTypes.func
  }),
  footerProps: PropTypes.shape({
    data: PropTypes.array
  }),
  dragAndDropProps: PropTypes.shape({
    patchData: PropTypes.func,
    mutateData: PropTypes.func,
    dragAndDropEnabled: PropTypes.bool,
    NonDraggableRows: PropTypes.array
  }),
  placeholderProps: PropTypes.shape({
    enabled: PropTypes.bool,
    src: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customPlaceholder: PropTypes.any
  }),
  collapseProps: PropTypes.shape({
    collapseEnabled: PropTypes.bool,
    dragAndDropEnabled: PropTypes.bool,
    patchData: PropTypes.func,
    mutateData: PropTypes.func,
    defaultOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.array])
  }),
  sortProps: PropTypes.shape({
    enabled: PropTypes.bool
  }),
  selectionProps: PropTypes.shape({
    enabled: PropTypes.bool,
    onSelectionChange: PropTypes.func
  }),
  paginationProps: PropTypes.shape({
    enabled: PropTypes.bool,
    queries: PropTypes.any,
    setQueries: PropTypes.func,
    count: PropTypes.number,
    page: PropTypes.number,
    setPage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    setRowsPerPage: PropTypes.func
  }),
  tableStyle: PropTypes.any
}

export default TableTemplate
